(function () {
    'use strict';

    angular
        .module('aerosApp')
        .factory('ToolLibFactory', ToolLibFactory);

    ToolLibFactory.$inject = ['$q', '$injector'];

    function ToolLibFactory($q, $injector) {
        var allTools = ['OTDR', 'OLTS', 'Inspection', 'Certification'];

        return function (toolType) {
            return toolType.toLowerCase() === 'all' ? AllToolsService() : $injector.get(toolType + 'LibResource');
        };

        function AllToolsService() {
            return {query: createCommand('query')};
        }

        function createCommand(cmdName) {
            return function () {
                var promise = $q.all(allTools.map(command(cmdName, arguments))).then(_.flatten);

                return {'$promise': promise};
            }
        }

        function command(method, args) {
            return function (toolType) {
                var resource = $injector.get(toolType + 'LibResource');

                return resource[method].apply(resource, args).$promise;
            }
        }
    }
}());
